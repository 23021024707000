<template>
  <div class="wrapper">
    <div class="header">
      <form class="search-inp-block" action="" @submit.prevent="onSubmit" ref="searchForm">
        <div class="search-pic"></div>
        <input type="text" class="search-inp" v-model="searchInp" autocomplete="off">
        <div
          class="destroy"
          v-show="searchInp.length"
          @click="searchInp=''"
        ></div>
      </form>
      <div class="back" @click="$router.push('/')">
        <div class="back-content">取消</div>
      </div>
    </div>
    <div class="classification">
      <div
        class="classification-item"
        v-for="(item, index) in classification"
        :key="index"
        @click="changeType(index)"
      >
        <div class="classification-item-content" :class="{active: classificationIndex===index}">{{ item }}</div>
        <div class="classification-item-bor" v-show="classificationIndex===index"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResHeader',
  props: {
    keywords: {
      type: String,
      default: ''
    },
    classificationIndex: {
      type: Number,
      default: 0
    }
  },
  activated () {
    if (this.keywords) {
      this.searchInp = this.keywords
    }
  },
  data () {
    return {
      searchInp: '',
      classification: [
        '全部',
        '看书',
        '听书',
        '书单',
        '视频',
        '活动'
      ]
    }
  },
  methods: {
    changeType (type) {
      this.$emit('changeType', type)
    },
    onSubmit () {
      this.$emit('search', this.searchInp)
      return false
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display table
  width 750px
  height 205px
  background rgba(255, 255, 255, 1)

  .header
    display flex
    align-items center
    width 720px
    padding-left 30px
    height 94px

    .search-inp-block
      display flex
      align-items center
      width 568px
      padding 0 26px
      height 60px
      border-radius 30px
      overflow hidden
      background rgba(245, 245, 244, 1)

      .search-pic
        width 20px
        height 20px
        bg-image('~@/assets/img/search/search')
        background-size 100% 100%
        background-repeat no-repeat

      .search-inp
        width 498px
        padding-left 20px
        height 60px
        border none
        -webkit-appearance none
        border-radius 0
        font-size 30px
        color rgba(51, 51, 51, 1)
        background rgba(245, 245, 244, 1)

      .destroy
        width 30px
        height 30px
        bg-image('~@/assets/img/login/destory')
        background-size 100% 100%
        background-repeat no-repeat

    .back
      display flex
      align-items center
      width 100px
      height 60px

      .back-content
        width 80px
        padding-left 20px
        line-height normal
        font-size 26px
        color rgba(102, 102, 102, 1)

  .classification
    display flex
    justify-content space-around
    align-items center
    width 750px
    height 118px

    .classification-item
      display table

      .classification-item-content
        font-size 28px
        color rgba(51, 51, 51, 1)
        margin-bottom 10px

        &.active
          font-size 34px
          color rgba(179, 53, 58, 1)
          font-weight bold

      .classification-item-bor
        width 30px
        height 6px
        margin 0 auto
        border-radius 3px
        background rgba(179, 53, 58, 1)
</style>
