<template>
  <div class="list">
    <div class="item" v-show="bookTotal > 0 && (classificationIndex === 0 || classificationIndex === 1)">
      <div class="title">电子书</div>
      <div class="ebook-list">
        <router-link
          tag="div"
          :to="'/home/book-detail/'+item.resources_guid+'/'+item.resources_name"
          class="ebook-item"
          v-for="(item, index) in bookList"
          :key="index"
        >
          <div class="ebook-cover">
            <img class="ebook-cover-img" :src="item.resources_cover"/>
          </div>
          <div class="ebook-info">
            <div class="ebook-info-title">
              {{ item.resources_name }}
            </div>
            <div class="ebook-info-desc">
              {{ item.resources_desc }}
            </div>
            <div class="ebook-info-author">
              <div class="ebook-info-author-icon"></div>
              <div class="ebook-info-author-author">{{ item.resources_author }}</div>
              <!--              <div class="ebook-info-author-label">电子书</div>-->
            </div>
          </div>
        </router-link>
      </div>
      <div class="item-total" v-show="bookTotal > 3 && bookTotal > bookList.length" @click="bookAdd">
        <div class="item-total-text">全部<span class="warning">{{ bookTotal }}</span>个结果</div>
        <div class="more-icon"></div>
      </div>
    </div>
    <div class="item" v-show="audioTotal > 0 && (classificationIndex === 0 || classificationIndex === 2)">
      <div class="title">听书</div>
      <div class="ebook-list">
        <div class="ebook-item" v-for="(item,index) in audioList" :key="index" @click="audioPlay(item)">
          <div class="ebook-cover">
            <img class="ebook-cover-img" :src="item.resources_cover"/>
          </div>
          <div class="ebook-info">
            <div class="ebook-info-title">
              {{ item.resources_name }}
            </div>
            <div class="ebook-info-desc">
              {{ item.resources_desc }}
            </div>
            <div class="ebook-info-author">
              <div class="ebook-info-author-icon"></div>
              <div class="ebook-info-author-author">{{ item.resources_author }}</div>
              <!--              <div class="ebook-info-author-label">听书</div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="item-total" v-show="audioTotal > 3 && audioTotal > audioList.length" @click="audioAdd">
        <div class="item-total-text">全部<span class="warning">{{ audioTotal }}</span>个结果</div>
        <div class="more-icon"></div>
      </div>
    </div>
    <div class="item" v-show="booksTotal > 0 && (classificationIndex === 0 || classificationIndex === 3)">
      <div class="title">书单</div>
      <div class="ebook-list">
        <router-link
          tag="div"
          :to="'/books/books-detail/'+item.resources_class_guid+'/'+item.resources_class_name"
          class="ebook-item"
          v-for="(item,index) in booksList"
          :key="index"
        >
          <div class="ebook-cover">
            <img class="ebook-cover-img" :src="item.cover"/>
          </div>
          <div class="ebook-info">
            <div class="ebook-info-title">
              {{ item.resources_class_name }}
            </div>
            <div class="ebook-info-desc">
              {{ item.resources_class_desc }}
            </div>
            <!--            <div class="ebook-info-author">-->
            <!--              <div class="ebook-info-author-icon"></div>-->
            <!--              <div class="ebook-info-author-author">Yuval Noah Harari（作者）</div>-->
            <!--              <div class="ebook-info-author-label">书单</div>-->
            <!--            </div>-->
          </div>
        </router-link>
      </div>
      <div class="item-total" v-show="booksTotal > 3 && booksTotal > booksList.length" @click="booksAdd">
        <div class="item-total-text">全部<span class="warning">{{ booksTotal }}</span>个结果</div>
        <div class="more-icon"></div>
      </div>
    </div>
    <div
      class="item"
      v-show="videoTotal > 0 && (classificationIndex === 0 || classificationIndex === 4)"
    >
      <div class="title">视频</div>
      <div class="video-list">
        <router-link
          tag="div"
          :to="'/home/video-detail/'+item.resources_guid+'/'+item.resources_name"
          class="video-item"
          v-for="(item,index) in videoList"
          :key="index"
        >
          <div class="video-item-img-block">
            <img class="video-item-img" :src="item.resources_cover"/>
          </div>
          <div class="video-desc">
            <div class="video-desc-content">{{ item.resources_name }}</div>
            <div class="video-desc-icon"></div>
          </div>
        </router-link>
      </div>
      <div class="item-total" v-show="videoTotal > 4 && videoTotal > videoList.length" @click="videoAdd">
        <div class="item-total-text">全部<span class="warning">{{ videoTotal }}</span>个结果</div>
        <div class="more-icon"></div>
      </div>
    </div>
    <div class="item" v-show="activityTotal > 0 && (classificationIndex === 0 || classificationIndex === 5)">
      <div class="title">活动</div>
      <div class="activity">
        <router-link
          tag="div"
          :to="'/home/activity-detail/'+item.activity_name+'/'+item.activity_guid"
          class="activity-info"
          v-for="(item, index) in activityList"
          :key="index"
        >
          <div class="info-pic">
            <img class="pic" :src="item.activity_cover"/>
            <div class="is-over" v-if="item.activity_is_over===1">已结束</div>
            <div class="on-line" v-else>{{ item.activity_type }}</div>
          </div>
          <div class="info-text">
            <div class="info-title">{{ item.activity_name }}</div>
            <div class="info-time">
              <div class="time-icon"></div>
              <div class="time-text">时间：{{ item.activity_time }}</div>
            </div>
            <div class="info-addr">
              <div class="addr-icon"></div>
              <div class="addr-text">地点：{{ item.activity_addr }}</div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="item-total" v-show="activityTotal > 3 && activityTotal > activityList.length" @click="activityAdd">
        <div class="item-total-text">全部<span class="warning">{{ activityTotal }}</span>个结果</div>
        <div class="more-icon"></div>
      </div>
    </div>
    <empty
      image="search"
      v-show="bookTotal === 0 && audioTotal === 0 && videoTotal === 0 && activityTotal === 0 && booksTotal === 0"
    ></empty>
  </div>
</template>

<script>
import { Empty, Toast } from 'vant'
import { getAudios } from '@/api/Resources'
import { appInit } from '@/utils/init'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SearchResList',
  props: {
    classificationIndex: {
      type: Number,
      default: 0
    },
    bookList: {
      type: Array,
      default () {
        return []
      }
    },
    bookTotal: {
      type: Number,
      default: 0
    },
    audioList: {
      type: Array,
      default () {
        return []
      }
    },
    audioTotal: {
      type: Number,
      default: 0
    },
    videoList: {
      type: Array,
      default () {
        return []
      }
    },
    videoTotal: {
      type: Number,
      default: 0
    },
    activityList: {
      type: Array,
      default () {
        return []
      }
    },
    activityTotal: {
      type: Number,
      default: 0
    },
    booksList: {
      type: Array,
      default () {
        return []
      }
    },
    booksTotal: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'userGuid'
    ])
  },
  components: {
    Empty
  },
  methods: {
    audioPlay (item) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getAudios({
        resources_guid: item.resources_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          if (!res.data.list.length) {
            Toast.fail('暂无音频数据')
            return false
          }
          this.selectPlay({
            list: res.data.list,
            index: res.data.current_index
          })
        } else if (res.code === 401) {
          appInit(this.agencyGuid, this.$route.path)
        } else if (res.code === 402) {
          this.$router.push({
            name: 'Login',
            params: {
              link: this.$route.path
            }
          })
        } else {
          Toast.fail(res.msg)
        }
        Toast.clear()
      })
    },
    bookAdd () {
      this.$emit('bookAdd')
    },
    audioAdd () {
      this.$emit('audioAdd')
    },
    booksAdd () {
      this.$emit('booksAdd')
    },
    videoAdd () {
      this.$emit('videoAdd')
    },
    activityAdd () {
      this.$emit('activityAdd')
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.list
  width 750px
  padding-bottom 30px

  .item
    display flex
    flex-wrap wrap
    justify-content center
    width 750px
    padding-bottom 20px
    border-bottom 10px solid rgba(239, 239, 238, 1)

    &:last-child
      border-bottom none

    .title
      width 722px
      padding-left 20px
      height 30px
      line-height 34px
      font-size 30px
      color rgba(51, 51, 51, 1)
      border-left 8px solid rgba(179, 53, 58, 1)
      margin-bottom 24px
      margin-top 38px

    .ebook-list
      width 690px

      .ebook-item
        display flex
        align-items center
        width 650px
        padding 0 20px
        height 270px
        border-radius 10px
        background rgba(255, 255, 255, 1)
        box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
        margin-bottom 10px

        .ebook-cover
          width 166px
          height 228px
          border-radius 10px
          overflow hidden

          .ebook-cover-img
            width 100%
            height 100%

        .ebook-info
          display flex
          flex-wrap wrap
          align-items center
          width 450px
          height 228px
          margin-left 25px

          .ebook-info-title
            width 450px
            line-height normal
            font-size 26px
            color rgba(51, 51, 51, 1)
            margin-top 10px
            no-wrap()

          .ebook-info-desc
            width 450px
            line-height normal
            font-size 24px
            color rgba(153, 153, 153, 1)
            margin-top 10px
            no-wrap2(2)

          .ebook-info-author
            display flex
            align-items center
            width 450px
            height 32px

            .ebook-info-author-icon
              width 16px
              height 16px
              bg-image('~@/assets/img/reading/author')
              background-size 100% 100%
              background-repeat no-repeat

            .ebook-info-author-author
              width 300px
              line-height normal
              font-size 22px
              color rgba(153, 153, 153, 1)
              margin-left 10px
              no-wrap()

            .ebook-info-author-label
              margin-left auto
              padding 0 10px
              line-height normal
              font-size 20px
              color rgba(245, 151, 1, 1)
              border-radius 6px
              border 1px solid rgba(245, 151, 1, 1)

    .item-total
      display flex
      justify-content center
      align-items center
      width 750px
      margin-top 20px

      .item-total-text
        line-height normal
        font-size 26px
        color rgba(153, 153, 153, 1)

        .warning
          color rgba(179, 53, 58, 1)

      .more-icon
        width 18px
        height 10px
        margin-left 14px
        bg-image('~@/assets/img/book-detail/more')
        background-size 100% 100%
        background-repeat no-repeat

    .video-list
      display flex
      flex-wrap wrap
      width 690px
      margin 44px auto 0 auto

      .video-item
        position relative
        width 336px
        height 240px
        border-radius 10px
        overflow hidden
        margin-bottom 20px

        &:nth-child(2n-1)
          margin-right 18px

        .video-item-img-block
          width 336px
          height 240px

          .video-item-img
            width 100%
            height 100%

        .video-desc
          position absolute
          bottom 0
          display flex
          align-items center
          width 336px
          height 62px
          background rgba(0, 0, 0, .6)

          .video-desc-content
            padding-left 14px
            width 280px
            height 30px
            line-height 34px
            font-size 26px
            color rgba(255, 255, 255, 1)
            no-wrap()

          .video-desc-icon
            width 30px
            height 30px
            bg-image('~@/assets/img/video/video-play')
            background-size 100% 100%
            background-repeat no-repeat

    .activity
      width 690px
      margin 0 auto
      padding-top 27px
      box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
      border-radius 10px
      background #fff
      overflow hidden

      .activity-info
        display flex
        width 652px
        height 170px
        padding-bottom 30px
        margin 0 auto 30px auto
        border-bottom 1px solid rgba(215, 215, 215, .5)

        &:last-child
          border-bottom none
          margin-bottom 0

        .info-pic
          position relative
          width 220px
          height 170px
          border-radius 10px
          overflow hidden

          .pic
            width 100%
            height 100%

          .on-line
            position absolute
            bottom 0
            right 0
            width 110px
            height 46px
            border-radius: 10px 0 10px 0
            line-height 46px
            text-align center
            font-size 22px
            color rgba(255, 255, 255, 1)
            background rgba(0, 0, 0, .4)

          .is-over
            position absolute
            left 55px
            top 30px
            width 110px
            height 110px
            line-height 110px
            text-align center
            font-size 24px
            color rgba(102, 102, 102, 1)
            background rgba(199, 199, 199, 1)
            border-radius 50%

        .info-text
          width 432px
          height 170px

          .info-title
            width 404px
            height 60px
            line-height 34px
            padding 6px 0 0 28px
            font-size 26px
            color rgba(51, 51, 51, 1)
            no-wrap2(2)

          .info-time
            display flex
            align-items center
            width 432px
            height 22px
            line-height normal
            margin-top 41px

            .time-icon
              width 20px
              height 20px
              margin-left 28px
              bg-image('~@/assets/img/time')
              background-size 100% 100%
              background-repeat no-repeat

            .time-text
              width 385px
              margin-left 10px
              font-size 22px
              color rgba(153, 153, 153, 1)
              no-wrap()

          .info-addr
            display flex
            align-items center
            width 432px
            height 22px
            line-height normal
            margin-top 14px

            .addr-icon
              width 20px
              height 20px
              margin-left 28px
              bg-image('~@/assets/img/addr')
              background-size 100% 100%
              background-repeat no-repeat

            .addr-text
              width 385px
              margin-left 10px
              font-size 22px
              color rgba(153, 153, 153, 1)
              no-wrap()
</style>
