<template>
  <div @touchmove.prevent>
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <search-res-header
          :keywords="$route.params.keywords"
          @search="search"
          :classification-index="classificationIndex"
          @changeType="changeType"
        ></search-res-header>
        <search-res-list
          :classification-index="classificationIndex"
          :book-list="bookList"
          :book-total="bookTotal"
          :audio-list="audioList"
          :audio-total="audioTotal"
          :video-list="videoList"
          :video-total="videoTotal"
          :activity-list="activityList"
          :activity-total="activityTotal"
          :books-list="booksList"
          :books-total="booksTotal"
          @bookAdd="bookAdd"
          @audioAdd="audioAdd"
          @booksAdd="booksAdd"
          @videoAdd="videoAdd"
          @activityAdd="activityAdd"
        ></search-res-list>
      </div>
    </scroll>
    <router-view></router-view>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import SearchResHeader from './components/Header'
import SearchResList from './components/List'
import { Toast } from 'vant'
import { getResourcesPageList } from '@/api/Resources'
import { mapGetters } from 'vuex'
import { getActivityPageList } from '@/api/Activity'
import { getBooksList } from '@/api/Books'

export default {
  name: 'SearchRes',
  components: {
    Scroll,
    SearchResHeader,
    SearchResList
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  activated () {
    this._searchInit()
  },
  methods: {
    _searchInit () {
      if (this.$route.params.keywords) {
        this.search(this.$route.params.keywords)
      }
    },
    async search (keywords) {
      this.searchInp = keywords
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      this.bookList.splice(0, this.bookList.length)
      this.bookPage = 1
      this.audioList.splice(0, this.audioList.length)
      this.audioPage = 1
      this.videoList.splice(0, this.videoList.length)
      this.videoPage = 1
      this.activityList.splice(0, this.activityList.length)
      this.activityPage = 1
      this.booksList.splice(0, this.booksList.length)
      this.booksPage = 1
      await Promise.all([
        getResourcesPageList({
          agency_guid: this.agencyGuid,
          resources_type: [1, 5],
          page: this.bookPage,
          limit: 3,
          resources_name: keywords,
          field: 'resources_read_num',
          order: 'desc'
        }).then(res => {
          if (res.code === 200) {
            this.bookList = res.data.list
            this.bookTotal = res.data.count
          }
        }),
        getResourcesPageList({
          agency_guid: this.agencyGuid,
          resources_type: [2, 6],
          page: this.audioPage,
          limit: 3,
          resources_name: keywords,
          field: 'resources_read_num',
          order: 'desc'
        }).then(res => {
          if (res.code === 200) {
            this.audioList = res.data.list
            this.audioTotal = res.data.count
          }
        }),
        getResourcesPageList({
          agency_guid: this.agencyGuid,
          resources_type: 3,
          page: this.videoPage,
          limit: 4,
          resources_name: keywords,
          field: 'resources_read_num',
          order: 'desc'
        }).then(res => {
          if (res.code === 200) {
            this.videoList = res.data.list
            this.videoTotal = res.data.count
          }
        }),
        getActivityPageList({
          page: this.activityPage,
          limit: 3,
          activity_name: keywords
        }).then(res => {
          if (res.code === 200) {
            this.activityList = res.data.list
            this.activityTotal = res.data.count
          }
        }),
        getBooksList({
          resources_class_agency: this.agencyGuid,
          page: this.booksPage,
          limit: 3,
          resources_class_name: keywords
        }).then(res => {
          if (res.code === 200) {
            this.booksList = res.data.list
            this.booksTotal = res.data.count
          }
        })
      ])
      this.refresh()
      Toast.clear()
    },
    changeType (type) {
      this.classificationIndex = type
      this.refresh()
    },
    bookAdd () {
      if (this.bookTotal > this.bookList.length && this.searchInp) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        this.bookPage++
        getResourcesPageList({
          agency_guid: this.agencyGuid,
          resources_type: [1, 5],
          page: this.bookPage,
          limit: 3,
          resources_name: this.searchInp,
          field: 'resources_read_num',
          order: 'desc'
        }).then(res => {
          if (res.code === 200) {
            this.bookList = this.bookList.concat(res.data.list)
            this.refresh()
          }
          Toast.clear()
        })
      }
    },
    audioAdd () {
      if (this.audioTotal > this.audioList.length && this.searchInp) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        this.audioPage++
        getResourcesPageList({
          agency_guid: this.agencyGuid,
          resources_type: [2, 6],
          page: this.audioPage,
          limit: 3,
          resources_name: this.searchInp,
          field: 'resources_read_num',
          order: 'desc'
        }).then(res => {
          if (res.code === 200) {
            this.audioList = this.audioList.concat(res.data.list)
            this.refresh()
          }
          Toast.clear()
        })
      }
    },
    booksAdd () {
      if (this.booksTotal > this.booksList.length && this.searchInp) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        this.booksPage++
        getBooksList({
          resources_class_agency: this.agencyGuid,
          page: this.booksPage,
          limit: 3,
          resources_class_name: this.searchInp
        }).then(res => {
          if (res.code === 200) {
            this.booksList = res.bookList.concat(res.data.list)
            this.refresh()
          }
          Toast.clear()
        })
      }
    },
    videoAdd () {
      if (this.videoTotal > this.videoList.length && this.searchInp) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        this.videoPage++
        getResourcesPageList({
          agency_guid: this.agencyGuid,
          resources_type: 3,
          page: this.videoPage,
          limit: 4,
          resources_name: this.searchInp,
          field: 'resources_read_num',
          order: 'desc'
        }).then(res => {
          if (res.code === 200) {
            this.videoList = this.videoList.concat(res.data.list)
            this.refresh()
          }
          Toast.clear()
        })
      }
    },
    activityAdd () {
      if (this.activityTotal > this.activityList.length && this.searchInp) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        this.activityPage++
        getActivityPageList({
          page: this.activityPage,
          limit: 3,
          activity_name: this.searchInp
        }).then(res => {
          if (res.code === 200) {
            this.activityList = this.activityList.concat(res.data.list)
            this.refresh()
          }
          Toast.clear()
        })
      }
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  },
  data () {
    return {
      classificationIndex: 0,
      searchInp: '',
      bookPage: 1,
      bookTotal: 0,
      bookList: [],
      audioPage: 1,
      audioTotal: 0,
      audioList: [],
      videoPage: 1,
      videoTotal: 0,
      videoList: [],
      activityPage: 1,
      activityTotal: 0,
      activityList: [],
      booksPage: 1,
      booksTotal: 0,
      booksList: [],
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.scroll
  position absolute
  top 0
  left 0
  right 0
  bottom 97px
  bottom calc(constant(safe-area-inset-bottom) + 97px)
  bottom calc(env(safe-area-inset-bottom) + 97px)
  overflow hidden
</style>
